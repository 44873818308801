import React from 'react';
import { Col, Row } from "react-bootstrap";
import { Link } from "gatsby";
import './assets/styles/_index.scss';
import areaImg from "../../images/area-guide-img.png";

const PropertyDetailsAreaGuide = (props) => {
    return (
        <Row>
            <Col lg={8} md={6} className="order-md-1 order-2">
                <div className="area-guide-heading">Dubai Harbour Area Guide</div>
                <p className="area-guide-text">Dubai Harbour is a luxurious waterfront development by Meraas Holding spanning over 20 million sq. ft. It is located at the intersection of the iconic Palm Jumeirah and Bluewaters Island, off King Salman bin Abdulaziz Al Saud Street in an area also known as Mina Al Seyahi.</p>
                <Link to="#" className="area-guide-link">Continue Reading</Link>
            </Col>
            <Col lg={4} md={6} className="order-md-2 order-1">
                <img src={areaImg} className="property-area-img" />
            </Col>
        </Row>
    )
}

export default PropertyDetailsAreaGuide