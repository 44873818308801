import React, { useState, useEffect, useRef } from 'react';
import { navigate, PageRenderer, Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import Modal from "react-modal";
import parse from "html-react-parser";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import $ from "jquery";
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import PropertyDetailsMap from "../components/PropertyDetailsMap/PropertyDetailsMap";
import PropertyDetailsAreaGuide from "../components/PropertyDetailsAreaguide/PropertyDetailsAreaGuide";
import PropertyDetailsCalculator from "../components/PropertyDetailsCalculator/PropertyDetailsCalculator";
import SimilarProperties from "../components/SimilarProperties/SimilarProperties";
import PropertyDetailsFooter from "../components/PropertyDetailsFooter/PropertyDetailsFooter";
import Footer from "../components/Footer/Footer";
import Share from "../components/Share/share";
import Logo from "../images/icons/logo.svg";
import negoAvatar from "../images/nego-avatar.png"
import negoImg from "../images/nego-img.png"
import { SaveItem, useUserObjectState, useAuthState, UserObjectStoreProvider } from "@myaccount/website-service";
import { getWhatsAppURL } from '../common/misc';
import SEO from '../components/Seo/seo';
import { isMobileOnly } from 'react-device-detect';
import { phoneNumber } from '../components/common/site/utils';
import { useMedia } from 'react-use';

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!) {
    properties(where:{id: $id}) {
      id
      search_type
      status
      department
      publish
      price
      price_qualifier
      max_price
      currency
      display_address
      bedroom
      bathroom
      reception
      building
      floor_area
      floorarea_min
      floorarea_max
      floorarea_type
      floorplan
      crm_negotiator_id
      latitude
      longitude
      accomadation_summary
      long_description
      images
      imagetransforms
      video_tour
      virtual_tour
    }
  }
`;

const PropertyDetailsTemplate = (props) => {

  const { state, services } = useUserObjectState()
  const { services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()

  useEffect(() => {
      if (isAuthenticated) {
      // on page load get the users saved objects
          services.getUserobject({ type: "property", state:true })
      }
  }, [])


  // 
  const pageurl = typeof window !== 'undefined' ? window.location.href : ''
  let myid_filt = pageurl.split('?');
  let mypageurl = myid_filt[0];
  let property_slug = mypageurl.split('-');
  let id = property_slug[property_slug.length - 1];
  id = id.replace("/", "");

  const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
      variables: { id: id }
  });

  // console.log("$$$$$---", data?.properties[0]?.search_type)
  // 

  // Modal styles
  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.58)",
      zIndex: '2'
    },
    content: {
      position: "relative",
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      maxWidth: "79%",
      margin: "auto",
      padding: 0,
      border: 0,
    },
  };
  // Modal styles

  // 
  var searchType = data?.properties[0]?.search_type;
  var resultPath = "";
  
  if ( searchType === "sales" ){
    resultPath = "/property/for-sale/in-dubai"
  }
  else if ( searchType === "lettings" ){
    resultPath = "/property/for-rent/in-dubai"
  }

  const [modalOpen, setModalOpen] = useState(true);
  const modalCloseTimeout = 300;
  const closeModal = () => {
    //setModalOpen(false);
    setTimeout(() => navigate(`${resultPath}`), modalCloseTimeout);
  };
  // 

  // PageRenderer
  const building = typeof window === "undefined";
  const [indexPageDataSales, setIndexPageDataSales] = useState(
      !building && window.indexPageDataSales
  );
  const [indexPageDataRentals, setIndexPageDataRentals] = useState(
    !building && window.indexPageDataRentals
  );

  // scroll events
  const listInnerRef = useRef();

  const onScrollRight = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop > 10) {
        $(".property-details-header-wrapper").addClass("scroll");
        $(".property-scroll-list-right").addClass("scroll");
        $(".property-contact-details").addClass("scroll");
      } else {
        $(".property-details-header-wrapper").removeClass("scroll");
        $(".property-scroll-list-right").removeClass("scroll");
        $(".property-contact-details").removeClass("scroll");
      }
    }
  };
  // scroll events

  useEffect(() => {
    if ( searchType === "sales" ) {
        window.setIndexPageDataSales = () => {
          setIndexPageDataSales(window.indexPageDataSales);
        };
    } else if ( searchType === "lettings" ) {
        window.setIndexPageDataRentals = () => {
          setIndexPageDataRentals(window.indexPageDataRentals);
        };
    }
  }, []);

  // Key details scroll
  const [isActiveKey, setIsActiveKey] = useState(false);
  const refKeyDetails = useRef(null);

  const handleClickkeyDetails = () => {
    refKeyDetails.current?.scrollIntoView({behavior: 'smooth'});
    setIsActiveKey(current => !current);
    setIsActiveDesc(false);
    setIsActiveLoc(false);
    setIsActiveTour(false);
    setIsActiveFloor(false);
    setIsActiveCalc(false);
  }
  // Key details scroll

  // Description scroll
  const [isActiveDesc, setIsActiveDesc] = useState(false);
  const refpropertyDesc = useRef(null);

  const handleClickpropertyDesc = () => {
    refpropertyDesc.current?.scrollIntoView({behavior: 'smooth'});
    setIsActiveDesc(current => !current);
    setIsActiveKey(false);
    setIsActiveLoc(false);
    setIsActiveTour(false);
    setIsActiveFloor(false);
    setIsActiveCalc(false);
  }
  // Description scroll

  // Location scroll
  const [isActiveLoc, setIsActiveLoc] = useState(false);
  const refpropertyLoc = useRef(null);

  const handleClickpropertyLoc = () => {
    refpropertyLoc.current?.scrollIntoView({behavior: 'smooth'});
    setIsActiveLoc(current => !current);
    setIsActiveKey(false);
    setIsActiveTour(false);
    setIsActiveFloor(false);
    setIsActiveCalc(false);
    setIsActiveDesc(false);
  }
  // Location scroll

  // 360 Tour scroll
  const [isActiveTour, setIsActiveTour] = useState(false);
  const refpropertyTour = useRef(null);

  const handleClickpropertyTour = () => {
    refpropertyTour.current?.scrollIntoView({behavior: 'smooth'});
    setIsActiveTour(current => !current);
    setIsActiveKey(false);
    setIsActiveCalc(false);
    setIsActiveDesc(false);
    setIsActiveLoc(false);
    setIsActiveFloor(false);
  }
  // 360 Tour scroll

  // Floorplan scroll
  const [isActiveFloor, setIsActiveFloor] = useState(false);
  const refpropertyFloor = useRef(null);
  
  const handleClickpropertyFloor = () => {
    refpropertyFloor.current?.scrollIntoView({behavior: 'smooth'});
    setIsActiveFloor(current => !current);
    setIsActiveKey(false);
    setIsActiveCalc(false);
    setIsActiveDesc(false);
    setIsActiveLoc(false);
    setIsActiveTour(false);
  }
  // Floorplan scroll

  // Mortgage calc scroll
  const [isActiveCalc, setIsActiveCalc] = useState(false);
  const refpropertyCalc = useRef(null);

  const handleClickpropertyCalc = () => {
    refpropertyCalc.current?.scrollIntoView({behavior: 'smooth'});
    setIsActiveCalc(current => !current);
    setIsActiveKey(false);
    setIsActiveDesc(false);
    setIsActiveLoc(false);
    setIsActiveTour(false);
    setIsActiveFloor(false);
  }
  // Mortgage calc scroll

  // Property images lightbox
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openPropertyImage = (e, ind) => {
      e.preventDefault()
      setPhotoIndex(ind)
      setIsOpen(true)
  }
  // Property images lightbox

      // 
      const shareurl = typeof window !== 'undefined' ? window.location.href : ''
      const trackerEvent = (event) => {    
       window.dataLayer = window.dataLayer || [];
       window.dataLayer.push({
           'event': event,
           'formType': event + ' - ' +shareurl,
           'formId': event,
           'formName': event,
           'formLabel': event
       });    
       }
    
      // 
  // const showReadMore = data && data.properties[0]?.long_description?.length > 400
  const showReadMore = true

  const [readMore, setReadMore] = useState(showReadMore)

  useEffect(() => {
    let element = ""

      setTimeout(() => {
        element = document.getElementById("description-content")
    
        if (readMore) {
          // element?.style.maxHeight = "270px"
          element?.setAttribute("style", "max-height:250px")
        } else {
          element?.setAttribute("style", "max-height:1000px")
        }
      });
  }, [readMore, data])

  // 
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  // 
  const isTabletOnly = useMedia("(min-width: 768px) and (max-width: 1024px)", false)
  
  return (
    <>
    {
      data && data?.properties && data?.properties.length > 0 ?
      (
        data?.properties.map((data, i) => {

          // // Property images lightbox
          // const [photoIndex, setPhotoIndex] = useState(0)
          // const [isOpen, setIsOpen] = useState(false)

          // const openPropertyImage = (e, ind) => {
          //     e.preventDefault()
          //     setPhotoIndex(ind)
          //     setIsOpen(true)
          // }
          // // Property images lightbox

          // Property details images lightbox
          const propertyImages = data?.images
          var propertyLightImages =
              propertyImages && propertyImages.map(img => img.url)
          // Property details images lightbox

          // console.log("$$$$$$$$---", data)

          // var searchaction = data?.properties[0]?.search_type === "sales"?"for sale":"for rent"
          var searchaction = data?.search_type === "sales"?"for sale":"for rent"         
          var metatile = ""+capitalize(data.building)+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at £"+data.price.toLocaleString();
          var metadesc = "Know the details of "+capitalize(data.building)+" "+searchaction+" with "+data.bedroom+" bedrooms in "+data.display_address+" at £"+data.price.toLocaleString()+". Book a viewing to get assistance in finding the right "+capitalize(data.building)+" for you.";
          // console.log("$$$$$$-----------", data.building)

          return (
            <>
              {
                data.search_type === "sales" &&
                <PageRenderer key={resultPath} path={resultPath}
                  location={{ pathname: '/property/for-sale/in-dubai' }}
                  pageResources={indexPageDataSales}
                />
              }

              {
                data.search_type === "lettings" &&
                <PageRenderer key={resultPath} path={resultPath}
                  location={{ pathname: '/property/for-rent/in-dubai' }}
                  pageResources={indexPageDataRentals}
                />
              }
              <SEO image={data.images && data.images[0].url} title={metatile} description={metadesc} />
              <Modal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Modal p-0"
                closeTimeoutMS={modalCloseTimeout}
                shouldCloseOnOverlayClick={true}
                className="property-details-popup"
              >
                <section className="property-details-modal">
                  <Container fluid className="property-details-container">
                    <Row>
                      <Col lg={7} className="pe-0 d-lg-block d-none">
                        <div className="property-left-scroll">
                          <div className="property-details-image-large">
                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                              <img src={data?.images[0].srcUrl} alt='property image' className="img-fluid" />
                            </a>
                            <ul className="list-inline property-details-img-list">
                              <li className="list-inline-item">
                                <Link to="#" className="d-flex align-items-center"><i className="icon icon-property-video-black"></i> 360 Tour</Link>
                              </li>
                              <li className="list-inline-item">
                                <Link to="#" className="d-flex align-items-center"><i className="icon icon-property-floor-black"></i> Floorplan</Link>
                              </li>
                            </ul>
                          </div>
                          <Row>
                            {
                              data?.images.slice(1, data?.images?.length).map((data, i) => {
                                return (
                                <Col lg={6} className="property-image-wrapper-padding">
                                  <div className="property-details-image-small">
                                    <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                                      <img src={data?.srcUrl} alt='property-img' className="img-fluid" />
                                    </a>
                                  </div>
                                </Col>
                                )
                              })
                            }
                          </Row>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div className="property-details-right-bg">
                          <div className="property-details-header-wrapper">
                            <div className="d-flex justify-content-between align-items-center">
                              <Link to="/">
                                <img alt='property-img' src={Logo} className="property-details-logo" />
                              </Link>
                              <ul className="list-inline">
                                <li className="list-inline-item save-item">
                                  {/* <i className="icon icon-property-share"></i> */}
                                  <SaveItem type="property" pid={id}>
                                    <i onClick={()=>trackerEvent("Save Property")} className="icon icon-heart" />
                                  </SaveItem>

                                </li>
                                <li className="list-inline-item">
                                  {/* <i className="icon icon-property-share"></i> */}
                                  <Share iconclass="icon icon-property-share" text="" id={data?.id} />
                                </li>
                                <li className="list-inline-item">
                                  <button className="icon icon-property-close" onClick={(e) => { e.preventDefault(); closeModal(); }} ></button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="property-details-image-large d-lg-none d-block">
                            <a href="javascript:void(0)" onClick={e => openPropertyImage(e, 0)}>
                              <img src={data?.images[0].srcUrl} alt='property-img' className="img-fluid" />
                            </a>
                            <ul className="list-inline property-details-img-list">
                              <li className="list-inline-item">
                                <a href="javascript:void(0)" className="d-flex align-items-center"><i className="icon icon-property-video-black"></i> 360 Tour</a>
                              </li>
                              <li className="list-inline-item">
                                <a href="javascript:void(0)" className="d-flex align-items-center"><i className="icon icon-property-floor-black"></i> Floorplan</a>
                              </li>
                            </ul>
                          </div>
                          <div className="property-details-wrapper">
                            {
                              data?.price &&
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="property-price-details">AED {data?.price.toLocaleString()}</div>
                                <ul className="list-inline property-scroll-list-right">
                                <li className="list-inline-item save-item">
                                  {/* <i className="icon icon-property-share"></i> */}
                                  <SaveItem type="property" pid={id}>
                                    <i onClick={()=>trackerEvent("Save Property")} className="icon icon-heart" />
                                  </SaveItem>

                                </li>
                                  <li className="list-inline-item">
                                    {/* <i className="icon icon-property-share"></i> */}
                                    <Share iconclass="icon icon-property-share" text="" id={data?.id} />
                                  </li>
                                  <li className="list-inline-item">
                                    <button className="icon icon-property-close" onClick={(e) => { e.preventDefault(); closeModal(); }} ></button>
                                  </li>
                                </ul>
                              </div>
                            }
                            {
                              data?.display_address &&
                              <div className="property-address-details">{data?.display_address}</div>
                            }
                            {
                              <ul className="list-inline property-features-list">
                                {
                                  data?.building &&
                                  <li className="list-inline-item">{capitalize(data?.building)}</li>
                                }
                                {
                                  data?.bedroom > 0 &&
                                  <li className="list-inline-item">{data?.bedroom} Bed</li>
                                }
                                {
                                  data?.bathroom > 0 &&
                                  <li className="list-inline-item">{data?.bathroom} Bath</li>
                                }
                                {
                                  data?.floorarea_min > 0 &&
                                  <li className="list-inline-item">{data?.floorarea_min.toLocaleString()} sqft</li>
                                }
                              </ul>
                            }
                            <div className="property-nego-details d-flex align-items-center">
                              <img src={negoAvatar} alt='nego-img' className="img-fluid" />
                              <div className="property-nego-name">{"Richard Harrison"}</div>
                              <div className="d-lg-none d-block ms-4">
                                <Link to="#"><i className="icon icon-property-whatsapp"></i></Link>
                                <Link to="#" className="ms-3"><i className="icon icon-property-phone"></i></Link>
                              </div>
                            </div>
                            <div className="property-btn-wrapper d-lg-flex d-none justify-content-start">
                              <Link to={`/book-a-viewing?pid=${data?.id}`} className="btn btn-black"><i className="icon icon-property-envelope"></i> Enquire</Link>
                              <a href={`tel:${phoneNumber}`} className="btn btn-white"><i className="icon icon-property-phone"></i> {phoneNumber}</a>
                              <a href={getWhatsAppURL(phoneNumber)} target={"_blank"} className="btn btn-white"><i className="icon icon-property-whatsapp"></i> Whatsapp</a>
                            </div>
                            <div className="property-contact-details d-md-flex align-items-center d-none">
                              <i className="icon icon-property-phone"></i>
                              <div className="property-contact">Jumeirah Office: <span>+971 38495 1029</span></div>
                            </div>
                          </div>

                          <div className="property-details-scroll-wrapper">
                            <Swiper
                              spaceBetween={50}
                              breakpoints={{
                                0: {
                                  slidesPerView: 3,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1300: {
                                  slidesPerView: 5,
                                }
                              }}
                              // slidesPerView={5}
                              navigation={true} 
                              modules={[Navigation]}
                              // onSlideChange={() => console.log('slide change')}
                              // onSwiper={(swiper) => console.log(swiper)}
                              className="property-details-scroll-list"
                            >
                              <SwiperSlide className="property-details-list-slide">
                                <a href="javascript:void(0)" className={isActiveKey  ? "list-link active" : "list-link"} onClick={handleClickkeyDetails}>Key Details</a>
                              </SwiperSlide>
                              <SwiperSlide className="property-details-list-slide">
                                <a href="javascript:void(0)" className={isActiveDesc  ? "list-link active" : "list-link"} onClick={handleClickpropertyDesc}>Description</a>
                              </SwiperSlide>
                              <SwiperSlide className="property-details-list-slide">
                                <a href="javascript:void(0)" className={isActiveLoc  ? "list-link active" : "list-link"} onClick={handleClickpropertyLoc}>Location</a>
                              </SwiperSlide>
                              <SwiperSlide className="property-details-list-slide">
                                <a href="javascript:void(0)" className={isActiveTour  ? "list-link active" : "list-link"} onClick={handleClickpropertyTour}>360 Tour</a>
                              </SwiperSlide>
                              <SwiperSlide className="property-details-list-slide">
                                <a href="javascript:void(0)" className={isActiveFloor  ? "list-link active" : "list-link"} onClick={handleClickpropertyFloor}>Floorplan</a>
                              </SwiperSlide>
                              <SwiperSlide className="property-details-list-slide">
                                <a href="javascript:void(0)" className={isActiveCalc  ? "list-link active" : "list-link"} onClick={handleClickpropertyCalc}>Mortgage</a>
                              </SwiperSlide>
                            </Swiper>

                            <div className="property-details-scroll-section" onScroll={() => onScrollRight()} ref={listInnerRef}>
                              {
                                data?.latitude && data?.longitude && (
                                  <div className="property-details-map-section" ref={refpropertyLoc}>
                                    <div className="property-details-scroll-heading">Location</div>
                                    <PropertyDetailsMap
                                      lat={data?.latitude}
                                      lng={data?.longitude}
                                    />
                                  </div>
                                )
                              }

                              <div className="property-key-details" ref={refKeyDetails}>
                                <div className="property-details-scroll-heading">Key Details</div>
                                <ul className="list-unstyled property-key-list">
                                  {
                                    data?.building &&
                                    <li className="d-flex align-items-center">
                                      <div className="key-details-heading">Property Type:</div>
                                      <div className="key-details-text">{capitalize(data?.building)}</div>
                                    </li>
                                  }
                                  {
                                    data?.floorarea_min > 0 &&
                                    <li className="d-flex align-items-center">
                                      <div className="key-details-heading">Property Size:</div>
                                      <div className="key-details-text">{data?.floorarea_min.toLocaleString()} sqft</div>
                                    </li>
                                  }
                                  {
                                    data?.bedroom > 0 &&
                                    <li className="d-flex align-items-center">
                                      <div className="key-details-heading">Bedrooms:</div>
                                      <div className="key-details-text">{data?.bedroom}</div>
                                    </li>
                                  }
                                  {
                                    data?.bathroom > 0 &&
                                    <li className="d-flex align-items-center">
                                      <div className="key-details-heading">Bathrooms:</div>
                                      <div className="key-details-text">{data?.bathroom}</div>
                                    </li>
                                  }
                                </ul>
                              </div>

                              {
                                data?.accomadation_summary?.length > 0 &&
                                <div className="property-amenities-details">
                                  <div className="property-details-scroll-heading">Amenities</div>
                                  <ul className="list-unstyled property-amenities-list">
                                    {
                                      data?.accomadation_summary.map((data, i) => {
                                        return (
                                          <li className="d-flex align-items-center">
                                            <div className="property-amenities-bg"></div>
                                            <div className="property-amenities-text">{data}</div>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                </div>
                              }

                              {
                                data?.long_description &&
                                <div className="property-details-desc" ref={refpropertyDesc}>
                                  <div className="property-details-scroll-heading">Description</div>
                                  <p className={`description ${showReadMore ? "description-read-more" : ""
                                    } ${readMore ? "read-more" : ""}`} 
                                    id={"description-content"}>
                                      {parse(data?.long_description)}
                                  </p>
                                  {showReadMore && (
                                    <button
                                      onClick={() => setReadMore(val => !val)}
                                      className={`read-more-button ${readMore ? "" : "read-less"}`}
                                    >
                                      {readMore ? "read more" : "read less"}
                                    </button>
                                  )}
                                </div>
                              }

                              {
                                data?.crm_negotiator_id &&
                                <div className="property-details-nego-wrapper">
                                  {!isTabletOnly &&<div className="property-details-scroll-heading agent-head">Property Agent</div>}
                                  <div className="d-flex justify-content-start">
                                    <img src={negoImg} alt='nego-img' className="img-fluid" />
                                    <div className="property-nego-wrapper">
                                      {isTabletOnly &&<div className="property-details-scroll-heading agent-head">Property Agent</div>}
                                      <div className="nego-name">{data?.crm_negotiator_id?.name}</div>
                                      <div className="nego-desig">Sales Agent</div>
                                      {
                                        data?.crm_negotiator_id?.phone &&
                                        <div className="nego-contact">
                                          <Link to="#" className="d-flex align-items-center">
                                            <i className="icon icon-nego-phone"></i>
                                            <span>{data?.crm_negotiator_id?.phone}</span>
                                          </Link>
                                        </div>
                                      }
                                      {
                                        data?.crm_negotiator_id?.phone &&
                                        <div className="nego-contact">
                                          <Link to="#" className="d-flex align-items-center">
                                            <i className="icon icon-nego-whatsapp"></i>
                                            <span>click to whatsapp</span>
                                          </Link>
                                        </div>
                                      }
                                      {
                                        data?.crm_negotiator_id?.email &&
                                        <div className="nego-contact">
                                          <Link to="#" className="d-flex align-items-center">
                                            <i className="icon icon-nego-mail"></i>
                                            <span>Email {data?.crm_negotiator_id?.name}</span>
                                          </Link>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              }

                              <div className="property-floorplan-wrapper" ref={refpropertyTour}>
                                <div className="property-details-scroll-heading">Floorplans & Tours</div>
                                <Row>
                                  <Col lg={12} md={6}>
                                    <Link to="#" className="d-flex align-items-center justify-content-between property-floorplan-card">
                                      <div>
                                        <i className="icon icon-property-video"></i>
                                        <span>360 Tour</span>
                                      </div>
                                      <i className="icon icon-property-arrow"></i>
                                    </Link>
                                  </Col>
                                  <Col lg={12} md={6} ref={refpropertyFloor}>
                                    <Link to="#" className="d-flex align-items-center justify-content-between property-floorplan-card">
                                      <div>
                                        <i className="icon icon-property-floorplan"></i>
                                        <span>Floorplans</span>
                                      </div>
                                      <i className="icon icon-property-arrow"></i>
                                    </Link>
                                  </Col>
                                </Row>
                              </div>

                              <div className="property-areaguide-wrapper">
                                <PropertyDetailsAreaGuide />
                              </div>

                              <div className="property-calc-wrapper" ref={refpropertyCalc}>
                                <PropertyDetailsCalculator price={data?.price} />
                              </div>

                              <div className="similar-properties-wrapper">
                                <SimilarProperties />
                              </div>

                              <div className="d-lg-block d-none">
                                <PropertyDetailsFooter />
                              </div>
                              <div className="d-lg-none d-block">
                                <Footer />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="property-btn-wrapper-fixed d-lg-none d-flex justify-content-between">
                          <Link to={`/book-a-viewing?pid=${data?.id}`} className="btn btn-black"><i className="icon icon-property-envelope"></i> <span className="enq-mobile">Enquire</span></Link>
                          <a href={`tel:${phoneNumber}`} className="btn btn-white"><i className="icon icon-property-phone"></i>{isMobileOnly&&<span>Call</span>} <span className="d-md-flex d-none">{phoneNumber}</span></a>
                          <a href={getWhatsAppURL(phoneNumber)} target={"_blank"} className="btn btn-white"><i className="icon icon-property-whatsapp"></i> <span className="whatspp-mobile">Whatsapp</span></a>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Modal>
              {isOpen && (
                <Lightbox
                    mainSrc={propertyLightImages[photoIndex]}
                    nextSrc={
                        propertyLightImages[
                        (photoIndex + 1) % propertyLightImages.length
                        ]
                    }
                    prevSrc={
                        propertyLightImages[
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                        (photoIndex + propertyLightImages.length - 1) %
                            propertyLightImages.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % propertyLightImages.length)
                    }
                />
              )}
            </>
          )
        })
      )
      : ""
    }
    </>
  );
}

const PropertyDetails = (props) => {
  return (
    <UserObjectStoreProvider>
      <PropertyDetailsTemplate {...props} />
    </UserObjectStoreProvider>
  )
}


export default PropertyDetails;
